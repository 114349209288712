"use client";
import { useState, forwardRef, useMemo } from "react";

import {
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatCellPhone,
  formatCurrency,
  formatDecimal,
  formatNumber,
  formatCpfOrCnpj,
  formatRG,
  formatMonthYear,
  formatCreditCard,
  formatRawNumber,
} from "@aprosoja/utils";

import { Icon } from "../icon/icon";
import { Label } from "../label/label";
import * as s from "./text-field.recipe";
import { type TextFieldProps } from "./text-field.types";
import { Text } from "../text/text";

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      type,
      startIcon,
      endIcon,
      required,
      disabled,
      label,
      errorMessage,
      supportLabel,
      labelIcon,
      helperIcon,
      helperText,
      onEndIconPress,
      size = "medium",
      maxCharacter,
      maxLength,
      mask,
      endUnit,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [showPassword, setShowPassword] = useState(false);

    const currentType = useMemo(() => {
      if (showPassword && type === "password") {
        return "text";
      }
      return type;
    }, [showPassword]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCharCount(event.target.value.length);
    };

    const actualMaxLength = () => {
      switch (mask) {
        case "creditCard":
          return 19;
        case "cnpj":
          return 18;
        case "cpfCnpj":
          return 18;
        case "phone":
          return 16;
        case "cpf":
          return 14;
        case "rg":
          return 13;
        case "cep":
          return 9;
        case "monthYear":
          return 5;
        default:
          return maxLength;
      }
    };

    const parseValue = (value: string) => {
      if (mask === "money" || mask === "decimal") {
        const valorSemPontuacoes = String(value).replace(/\D/g, "");

        // Ao dar backspace no campo com o valor R$ 0,00 ele retorna 00
        // Nesse caso, deverá deixar o campo vazio
        if (valorSemPontuacoes === "00") return "";

        const valorComPontuacao = Number(valorSemPontuacoes) / Math.pow(10, 2);

        return String(valorComPontuacao);
      } else {
        return value.replace(/\D/g, "");
      }
    };

    const masksFormat = (value: string, mask?: TextFieldProps["mask"]) => {
      switch (mask) {
        case "cep":
          return formatCEP(value);
        case "phone":
          return formatCellPhone(value);
        case "money":
          return formatCurrency(value);
        case "decimal":
          return formatDecimal(value);
        case "number":
          return formatNumber(value);
        case "rawNumber":
          return formatRawNumber(value);
        case "cpf":
          return formatCPF(value);
        case "cnpj":
          return formatCNPJ(value);
        case "cpfCnpj":
          return formatCpfOrCnpj(value);
        case "rg":
          return formatRG(value);
        case "monthYear":
          return formatMonthYear(value);
        case "creditCard":
          return formatCreditCard(value);
        default:
          return value;
      }
    };

    return (
      <div className={s.container({ disabled })}>
        {label && (
          <Label
            htmlFor="input"
            status={isFocused ? "focused" : "default"}
            required={required}
            supportLabel={supportLabel}
            icon={labelIcon}
            size={size === "large" ? "large" : "default"}
          >
            {label}
          </Label>
        )}

        <div
          className={s.textfield({
            status: isFocused ? "focused" : "default",
            error: !!errorMessage,
            size,
            className,
          })}
        >
          {startIcon && (
            <Icon
              name={startIcon}
              size="size-x5"
              color={
                errorMessage ? "th-error-light" : "th-primary-color-medium"
              }
            />
          )}
          <input
            {...props}
            ref={ref}
            id="input"
            type={currentType}
            disabled={disabled}
            className={s.input()}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onInput={handleInputChange}
            maxLength={maxCharacter ?? actualMaxLength()}
            value={masksFormat(props?.value as string, mask)}
            onChange={(e) => {
              if (mask) {
                const newValue = parseValue(e.target.value);
                e.target.value = newValue;
              }
              props.onChange?.(e);
            }}
          />
          {!errorMessage && endUnit && <Text type="paragraphM">{endUnit}</Text>}
          {!errorMessage && endIcon && onEndIconPress && (
            <button
              className="h-size-x5"
              type="button"
              onClick={onEndIconPress}
            >
              <Icon
                name={endIcon}
                size="size-x5"
                color="th-primary-color-medium"
              />
            </button>
          )}
          {!errorMessage && endIcon && !onEndIconPress && (
            <Icon
              name={endIcon}
              size="size-x5"
              color="th-primary-color-medium"
            />
          )}
          {errorMessage && (
            <Icon fill name="error" size="size-x5" color="th-error-light" />
          )}
          {type === "password" && (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              <Icon
                name={showPassword ? "visibility_off" : "visibility"}
                size={size === "large" ? "size-x5" : "size-x4"}
              />
            </div>
          )}
        </div>
        {maxCharacter && (
          <span className="text-th-color-neutral-400 self-end text-[10px]">
            {charCount}/{maxCharacter}
          </span>
        )}
        <div className={s.helperContainer({ error: !!errorMessage })}>
          {!errorMessage && helperIcon && (
            <Icon name={helperIcon} size="size-x3" color="th-info-light" />
          )}
          {errorMessage ?? helperText}
        </div>
      </div>
    );
  }
);

TextField.displayName = "TextField";

export { TextField };
