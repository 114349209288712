"use client";
import { useRouter } from "next/navigation";

import { Button } from "../button/button";

type FormPageButtonsProps = {
  callToActionLoading?: boolean;
  callToActionText?: string;
  callToActionDisabled?: boolean;
  secondaryButtonText?: string;
  onClean?: () => void;
  withSecondaryButton?: boolean;
};

export function FormPageButtons({
  callToActionLoading,
  callToActionText = "Salvar",
  secondaryButtonText = "Cancelar",
  callToActionDisabled,
  onClean,
  withSecondaryButton = true,
}: FormPageButtonsProps) {
  const { push } = useRouter();
  return (
    <div
      className={`grid ${
        (onClean && "grid-cols-3") ||
        (withSecondaryButton && "grid-cols-2") ||
        "grid-cols-1"
      } gap-size-x4 self-end`}
    >
      {onClean && (
        <Button type="button" size="large" variant="text" onClick={onClean}>
          Limpar
        </Button>
      )}
      {withSecondaryButton && (
        <Button
          type="button"
          size="large"
          variant="outlined"
          className="rounded-border-radius-small"
          onClick={() => push("/dashboard")}
        >
          {secondaryButtonText}
        </Button>
      )}
      <Button
        disabled={callToActionDisabled}
        size="large"
        type="submit"
        className="rounded-border-radius-small"
        loading={callToActionLoading}
      >
        {callToActionText}
      </Button>
    </div>
  );
}
