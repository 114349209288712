"use client";
import React, { useEffect, useState, useId } from "react";
import * as EditorPrimitive from "@tiptap/react";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

import { EditorHeader } from "./components/editor-header/editor-header";
import "./editor-content-css/editor-content.css";
import * as s from "./styles";
import { Icon, Label } from "../index";

export type EditorProps = {
  onChange: (value: string) => void;
  defaultValue?: string;
  isEditable?: boolean;
  label?: string;
  isRequired?: boolean;
  error?: string;
  placeholder?: string;
  clearEditorOnSubmit?: boolean;
};

export const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      isEditable = true,
      defaultValue = "",
      isRequired,
      label,
      error,
      onChange,
      placeholder,
      clearEditorOnSubmit = false,
      ...field
    }: EditorProps,
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    const id = useId();

    const editor = EditorPrimitive.useEditor({
      extensions: [
        Image.configure({}),
        StarterKit,
        Underline,
        Placeholder.configure({
          emptyEditorClass: "is-editor-empty",
          placeholder: placeholder,
          showOnlyWhenEditable: false,
        }),
        Link.configure({
          openOnClick: true,
          autolink: false,
          defaultProtocol: "https",
          protocols: ["http", "https"],
        }),
      ],
      immediatelyRender: false,
      content: value,
      editable: isEditable,
      onUpdate: (v) => {
        const html = v.editor.getHTML();
        setValue(html);
        onChange(html);
      },
    });

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue);
        onChange(defaultValue);
      }
    }, [defaultValue, onChange]);

    useEffect(() => {
      if (clearEditorOnSubmit) {
        editor?.commands.clearContent();
      }
    }, [clearEditorOnSubmit]);

    if (!editor) {
      return null;
    }

    return (
      <div className={s.container()} ref={ref}>
        {label && (
          <Label required={isRequired} htmlFor={id}>
            {label}
          </Label>
        )}
        <div className={s.editorContainer()}>
          <EditorHeader editor={editor} />
          <EditorPrimitive.EditorContent
            {...field}
            editor={editor}
            className="editor-content"
          />
        </div>
        {error && (
          <div className={s.errorTextField()}>
            <Icon
              size={"size-x4"}
              color={"th-error-dark"}
              name="close-circle"
            />
            {error}
          </div>
        )}
      </div>
    );
  }
);
