"use client";

import { ClipLoader } from "react-spinners";
import type { ButtonSize } from "../button.types";
import { IconColor } from "../../icon/icon.types";
import { colors } from "@aprosoja/styles/tokens";

interface LoadingProps {
  size: ButtonSize;
  color: IconColor;
}

export function Loading({ size, color }: LoadingProps) {
  const sizeMap = {
    small: 12,
    medium: 16,
    large: 20,
  };

  return (
    <div className={`h-[${sizeMap[size]}px]`}>
      <ClipLoader size={sizeMap[size]} color={colors[color]} />
    </div>
  );
}
