import { cva } from "class-variance-authority";

export const container = cva(
  "flex p-size-x2 gap-size-base border-b-[1px] border-b-th-color-neutral-200 "
);

export const groupItem = cva(`unset transition-all duration-200
ease-in-out text-th-color-neutral-800 hover:bg-th-color-neutral-100 rounded-border-radius-tiny
data-[state=on]:bg-th-color-neutral-200 focus:relative
cursor-pointer py-size-base px-size-x2 h-size-x8`);

export const item = cva(`unset transition-all duration-200
  ease-in-out text-th-color-neutral-800 hover:bg-th-color-neutral-100 rounded-border-radius-tiny
  focus:relative cursor-pointer py-size-base px-size-x2 h-size-x8`);
